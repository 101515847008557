import React from 'react';
import PropTypes from 'prop-types';
import LayoutWithSider from 'components/layout/withsider';
import { graphql } from 'gatsby';
import AppContext from 'store/provider';
import AntdTable from 'components/antdtable';

const Index = ({ data }) => {
  return (
    <AppContext.Consumer> 
    {({ horses, sane_horse_data, updateStatus, toggleSym, isLoaded, filteredInfo, setFilteredInfo }) => {
      
      return (
        <LayoutWithSider>
            <AntdTable 
              horses={horses} 
              sane_horse_data={sane_horse_data} 
              status={updateStatus} 
              toggleSym={toggleSym} 
              isLoaded={isLoaded} 
              filteredInfo={filteredInfo}
              setFilteredInfo={setFilteredInfo}
              />
        </LayoutWithSider> 
        )}}
    </AppContext.Consumer>
)};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`query HomepageQuery {
  homeJson {
    title
    content {
      childMarkdownRemark {
        html
        rawMarkdownBody
      }
    }
    gallery {
      title
      copy
      image {
        childImageSharp {
          gatsbyImageData(height: 500, quality: 90, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`;
